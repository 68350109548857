.portfolio-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
    
    .portfolio-header {
        width: 100%;
        display: block;
        text-align: center;
        .portfolio-title {
            font-family: "Raleway",sans-serif;
            font-weight: 500;
            color: #fff;
        }
    }
    .portfolio-body {
        
        width: 90%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: .9rem;
        margin: 2rem 0rem;
    
    }
}
.ham-btn {
    display: block;
      appearance: none;
      border: none;
      outline: none;
      background: none;
      position: absolute;
      top: 2.4rem;
      right: 1rem;
      
      cursor: pointer;
      span {
          display: block;
          height: 3px;
          width: 25px;
          background-color: #fff;
          border-radius: .2rem;
          margin-bottom: 5px;
          transform-origin: 0 0 ;
          transition: .5s ;
      }
      &:hover :nth-child(2){
          
          transform: translateX(10px);
          background-color: #31A8FF;
      }
  }
  .ham-btn.isActive span:nth-child(1){
      transform: translate(2px, -4px) rotate(45deg);
      background-color: #31A8FF;
  }
  .ham-btn.isActive span:nth-child(2){
      opacity: 0;
      transform:translate(15px)
  }
  .ham-btn.isActive span:nth-child(3){
      transform: translate(-1px, -3px) rotate(-45deg);
      background-color: #31A8FF;
  }

  @media only screen and (max-width:576px){
 .portfolio-body{
    width: 100%;
 }   
}
@media only screen and (min-width:577px) and (max-width:840px) {
    .portfolio-body{
        flex-direction: column;
        
    }
}