.about-container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 3rem 0rem;
    padding: 2rem;
    .skills-text-column {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        .skills-title {
            font-family: "Raleway", sans-serif;
            font-weight: 200;
            color: #fff;
            
        }
    }
    .skills-swiper-column {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        margin: auto;
        .swiper-button-prev{
            color:#fff;
            right: -20%;
        }
        .swiper-button-next{
            color:#fff;
            
        }
        .swiper {
            
          width: 100%;
        
        }
    }
   
    
    .tech {
        position: relative;
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
        cursor: pointer;
        // background-color: rgba(255,255,255,.1);
        .tech-name{
            position: absolute;
            inset: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h2,h3{
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-family: "Raleway", sans-serif;
                font-weight: 300;
            }
        }
        .percent {
            position: relative;
            width: 150px;
            height: 150px;
           
            svg {
                position: relative;
            width: 150px;
            height: 150px;
            transform: rotate(270deg);
                circle {
                    width: 100%;
                    height: 100%;
                    fill: transparent;
                    stroke-width: 3;
                    stroke:#3333334d;
                    transform:translate(5px,5px);
                    
                }
                & circle:nth-child(2){
                    stroke: var(--clr);
                    stroke-dasharray: 440;
                    stroke-dashoffset: calc(440 - (440 * var(--num) /100));
                    
                    
                }
                
            }
            .dot {
                position: absolute;
                inset: 5px;
                z-index: 10;
                transform: rotate( calc(3.6deg * var(--num)));
                animation: animateDot 2s linear forwards ;
                @keyframes animateDot {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(calc(3.6deg * var(--num)));
                    }
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: -5px;
                    left: 50%;
                    width: 10px;
                    height: 10px;
                    background-color: var(--clr);
                    border-radius: 50%;
                    box-shadow:  0 0 15px var(--clr),0 0 40px var(--clr);
                }
            }

        }
    }
    .swiperAnimate {
        opacity: 1;
        transform: translateX(0);
        animation: animateSwiper   .6s linear;
    }

    @keyframes animateSwiper {
        0%{
            opacity: .2;
            transform: translateX(100px);
        }
        50%{
            opacity: .8;
            transform: translateX(50px);
        }
        100%{
            opacity: 1;
            transform: translateX(0);
        }
        
    }

}
.about-container2 {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
        .img-container{
        height: 100%;
        width: 30%;
        padding: 3rem;
        position: relative;
        img {
            width: 100%;
        }
        .img-backlayer{
         width: 87%;
         height: 68%;
         position: absolute;
         top: 15%;
        //  left:11%;
         right: 1%;
         
       
         box-sizing: border-box;
         border: 2px solid #0071bc;
         
         z-index: -1;   
         
        }
     
        
    }
    .experiences{
        width: 50%;
        color: #fff;
        padding-right: 2rem;
      
        h2{
            font-family: "Raleway", sans-serif;
            font-weight: 500;
        }
        p{
            font-family: "Raleway", sans-serif;
            font-weight: 300;
            line-height: 25px;
            
            .react {
                color:#61dafb;
            }
        }
        
    }
    
}
.vector-container {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}   
.swiper-pagination-bullet{
    background-color: #ffffff!important;
    color: #fff!important;
}
@media only screen and (max-width:576px) {
    .about-container{
        flex-direction: column;
        height: 65%;
        .skills-text-column {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 1rem;
            .skills-title {
                font-family: "Raleway", sans-serif;
                font-weight: 200;
                color: #fff;
                font-size: 1.9rem;
                padding: 0rem;
                
            }
        }
        .skills-swiper-column {
            width: 93%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: .2rem 1rem;
            .swiper-wrapper {
                width: 576px;
                
              }
              .swiper-pagination {
                display: none;
            }
        }
       
        
        .tech {
            position: relative;
            width: 150px;
            height: 150px;
            margin: 0 auto;
        
                }
    
            
        
        
    }
    .about-container2{
        flex-direction: column;
        .img-container{
            width: 70%;
            .img-backlayer{
                width: 80%;
             height: 68%;
             position: absolute;
             top: 28%;
            //  left:11%;
             right: 1%;
            }
        }
        .experiences{
            width: 85%;
            margin-top: .5rem;
            padding-right: 0rem;
        }
    
    }
    .vector-container {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
     
        margin-bottom: 0;
        svg{
            height: 50%;
        }
    }   
}

@media only screen and (min-width:577px) and (max-width:840px) {
    
    .about-container{
        gap: 10px;
        margin: 0;
        height: 40%;
        .skills-swiper-column{
            width: 62%;
            .tech {
                width: 100%;
            }
        }
        .skills-text-column{
            width: 30%;
            h1 {
                font-size: 1.5rem;
            
            }
            
        }
    }
    .about-container2 {
        .img-container {
            height: 50%;
            img{
                margin: auto 0;
            }
            .img-backlayer {
                width: 77%;
                height: 48%;
                position: absolute;
                
                top: 20%;
                right:0%;
               
            }
        }
      
 }
 .vector-container {
    width: 95%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
 
    margin-bottom: 0;
    svg{
        height: 80%;
    }
}  
}

