

body {
    background: #000428;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to left, #001425, #000428);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to left, #001425, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    
    .custom-btn {
        font-family: "Raleway", sans-serif;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding: .6rem 1rem;
        
        text-decoration: none;
        background-color: transparent;
        text-transform: uppercase;
        letter-spacing: 1px;
        background-color: transparent;
        font-size: 1rem;
        overflow: hidden;
        -webkit-transition: .2s;
        transition: .2s;
        color:var(--clr1);
        border: none;
      }
      .custom-btn:hover {
          background-color: var(--clr-bg);
          box-shadow: 0 0 10px var(--clr2), 0 0 40px var(--clr2), 0 0 80px var(--clr2);
          color: #fff!important;
          border: none;
      }  
      .custom-btn span {
        position: absolute;
        display: block;
      }
      
      .custom-btn span:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: -webkit-gradient(linear, left top, right top, from(var(--clr2)), to(var(--clr2)));
        background: linear-gradient(90deg, var(--clr2), var(--clr2));
      }
      
      .custom-btn:hover span:nth-child(1) {
        left: 0;
        -webkit-transition: 1s;
        transition: 1s;
      }
      
      .custom-btn span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: -webkit-gradient(linear, left top, left bottom, from(var(--clr2)), to(var(--clr2)));
        background: linear-gradient(180deg, var(--clr2), var(--clr2));
      }
      
      .custom-btn:hover span:nth-child(2) {
        top: 0;
        -webkit-transition: 1s;
        transition: 1s;
        -webkit-transition-delay: .25s;
                transition-delay: .25s;
      }
      
      .custom-btn span:nth-child(3) {
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 2px;
        background: -webkit-gradient(linear, right top, left top, from(var(--clr2)), to(var(--clr2)));
        background: linear-gradient(270deg, var(--clr2), var(--clr2));
      }
      
      .custom-btn:hover span:nth-child(3) {
        right: 0;
        -webkit-transition: 1s;
        transition: 1s;
        -webkit-transition-delay: .5s;
                transition-delay: .5s;
      }
      
      .custom-btn span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 2px;
        height: 100%;
        background: -webkit-gradient(linear, left bottom, left top, from(var(--clr2)), to(var(--clr2)));
        background: linear-gradient(3600deg, var(--clr2), var(--clr2));
      }
      
      .custom-btn:hover span:nth-child(4) {
        bottom: 0;
        -webkit-transition: 1s;
        transition: 1s;
        -webkit-transition-delay: .75s;
                transition-delay: .75s;
      }
}