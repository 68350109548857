.project-container {
    height: 43vh;
    width: 48%;
    position: relative;
    cursor: pointer;
    background-image: var(--bg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #4251df,0px 0px 5px #fff;
    .project-img-container{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .project-overlay {
        min-width: 100%;
        min-height: 100%;
        background-color: #1f2669a9;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        transition: all .5s ease-in-out;
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(15px);
        
        h3 {
            color: #fff;
            font-family: "Ralewat",sans-serif;
            font-weight: 200;
            text-transform: capitalize;
            padding: .8rem;
        }
        p {
            color: rgb(228, 227, 227);
            font-family: "Ralewat",sans-serif;
            font-weight: 100;
            
            padding: .8rem;
        }
        .btns{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
&:hover {
    .project-overlay {
       
        opacity: 1;
      
       
        
        
    }
}
   
}

@media only screen and (max-width:576px){
    .project-container{
        width: 100%;
        height: 24vh;
        .project-overlay{

            h3,p{
                padding: 0rem .4rem;
                margin: .4rem 0rem;
            }
        }
    }
}
@media only screen and (min-width:577px) and (max-width:840px) {
    .project-container{
        width: 100%;
        height: 32vh;
    }
}