.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    .column-hero-text {
       
        height: 100%;
        
        display: flex;
        padding: 1rem;
        justify-content: center;
        flex-direction: column;
         color: #31A8FF;
         
        .fwd {
            color: #fff!important;
        }
      
    }
    .column-hero-svg {
        width: 50%;
        height: 80%;
      object{
        width: 100%;
        height: 100%;
      }
      
    }
    .hero-text{
      width: 100%;
        font-family: "Raleway",sans-serif;
        font-weight: 500;
       text-transform: capitalize;
    }
    .svg-banner {
        height: 50%;
        width: 100%;
    }
    
}

@media  only screen and (max-width:576px) {
  .container{
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    
    .column-hero-svg {
      width: 90%;
   
      height: 50%;
      
      text-align: center;
    object{
      width: 50%;
      height: 100%;
        
    }
    
  }
    .column-hero-text {
      height: 70%;
      width: 90%;
      justify-content: flex-start;
      font-size: .8rem;
      
      
    }
  }


}