.modal-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000bd;
    .modal-body {
      width: 70%;
        height: 90%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        z-index: 6;
        top: 50%;
        left: 50%;
        box-shadow: 0px 0px 10px #31A8FF,0px 0px 30px #31A8FF;
        transform: translate(-50%, -50%);
        backdrop-filter: blur(10px);
        color:#fff;
        border-radius: .5rem;
        // background-color: #212529;
        .modal-section1{
          width: 100%;
          height: 70%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          z-index: 8;
        .swiper-container {
          width: 60%;
          height: 80%;
          padding: 1rem 1.3rem;
          position: relative;
          
          
          img{
            height: 100%;
            width: 100%;
            
            
          }
        }
        .modal-text-container {
          height: 90%;
          width: 35%;
          padding:0rem 1rem;
          display: flex;
          color: #fff;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          text-transform: capitalize;
          font-family: "Raleway",sans-serif;
          font-weight: 400;
          
        }
      } .modal-techs {
        text-align: center;
        margin-top: auto;
        h2{
          font-family: "Raleway",sans-serif;
          font-weight: 500;
          color: #fff;
        }
        ul{
          list-style: none;
        margin: 0;
      padding: 0;}
        ul li{
          display: inline-block;
          margin: .5rem 1rem;
          font-family: "Raleway",sans-serif;
          font-weight: 300;
          color: #fff;
          text-transform: capitalize;
        }
      } }
     
}

@media only screen and (max-width:576px) {
 
 .modal-container{
  .modal-body{
    .modal-section1{
      flex-direction: column;
      .swiper-container{
        width: 90%;
       margin-top: auto;
      }
      .modal-text-container{
        width: 100%;
      }
    }
   } 
 }

}
@media only screen and (max-width:840px) {
.modal-container{
  .modal-body{
    width: 80%;
    .modal-section1{
      flex-direction: column;
      height: 50%;
        .swiper-container{
        width: 90%;
        height: 40%;
       margin-top: auto;
      }
      .modal-text-container{
        width:100%;
      height: 50%;
     }

    }
    .modal-techs{
      margin-bottom: auto;
      height: 30%;
    }
  }
}
}