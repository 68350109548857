.contact-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    
    .contact-header {
        width: 100%;
        height: 20%;
        h1{
            font-family: "Raleway",sans-serif;
            font-weight: 500;
            color: #fff;
            text-align: center;
        }
        h4{
            color: #bfe4ff;
            font-family: "Raleway",sans-serif;
            font-weight: 300;
            font-size: 1.2rem;
            text-align: center;
        }
    }
 
    .contact-body{
       height: 65%;
       width: 100%;
       display: flex;
        padding: 3rem 2rem;
       .contact-info{
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        .col-12{
            width: 100%;
            height: 20%;
            text-align: center;
            
        }
        .icons{
            width: 100%;
            height: 70%;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            margin-top: 5rem;
            justify-content: center;

            .col-4{
                width: 30%;
                height: 20%;
                text-align: center;
                cursor: pointer;
                img{
                    width: 15%;
                height: 50%;}
                a,span{
                    color:#fff;
                    text-decoration: none;
                    font-family: 'Raleway',sans-serif;
                    transition: all .3s ease-in-out;
                    &:hover{
                        color: #31A8FF;
                    }


                }
                span{
                    margin-left: .1rem;
                
                }
            }
        }
    
       }
       .form-container {
        width: 48%;
        height: 80%;
        
        
        .custom-form{
            height: 100%;
            width: 70%;
            font-family: 'Raleway',sans-serif;
            font-weight: 300;
            margin: 0 auto;
            position: relative;
            padding: 3rem 1rem;
           background: rgba(0, 4, 40,.7);
           .form-group{
            display: flex;
            flex-direction: column;
            color: #fff;
            margin: 1rem;
            margin-bottom: 2rem;
           
           }
           .btn-container {
            margin: 1rem 0rem 1rem 1.7rem;
           }
         
        }
       
        .custom-form::before {
            content: '';
            position: absolute;
            top: -1px;
            right: -1px;
            left: -1px;
            bottom: -1px;
            background-color: #3ca2dd;
            z-index: -1;
            isolation: isolate;
        }
        .custom-form::after{
            content: '';
            position: absolute;
            top: -2px;
            right: -2px;
            left: -2px;
            bottom: -2px;
            background-color: #fff;
            z-index: -2;
            filter: blur(40px);
        }
        .custom-form::before,.custom-form::after {
            background: linear-gradient(235deg,#cf0a8d7a,#1949ce);
        }
        .project-title{
            color:#31A8FF;
            background-color: transparent;
           
          
        }
        .form-control {
            width: 95%;
            height: 30px;
            margin:  .5rem auto;
            border: none;
            border-radius: 0;
            padding: 2px 10px;
            background-color: #b2cfe031;
            border-bottom: 2px solid #0274bf;
            transition: .3s all ease-in-out;
            color: #fff;
        }
        .text-ari{
            height: 60px;
        }
        .form-label {
            font-family: "Raleway",sans-serif;
            font-weight: 600;
            margin:  0rem 1rem;
        }
        .form-control:focus{
            outline: none;
            box-shadow: none;
            border-color: #38a0e6;
            box-shadow: 0px 0px 5px #38a0e6;
            
        }
    
    }
    }
   

        .contact-footer {
            width: 100%;
            height: 15%;
            .copyr{
                font-family: "Raleway",sans-serif;
                color: #fff;
                font-weight: 300;
                text-align: center;
                
                
            }
            .copy-divider{
                width: 100%;
                margin-top: 3rem;
                background-color:#0274bf;
                border: none;
                height: 1px;
                
            }
        }


}
.req-msg{
    font-family: "Raleway",sans-serif;
    font-weight: 500;
    color: #279ae7;
    text-align: end;
    text-transform: capitalize;
}

@media  only screen and (max-width:576px){
    .contact-container{
        margin-top: 2rem;
        .contact-header{
            height: 20%;
            padding: .5rem 0rem;
        }
            .contact-body{
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                height: 100%;
                .form-container{
                    justify-content: center;
                    width: 95%;
                    height: 60%;
                    margin-top: 1rem;
                    .custom-form {
                        padding: 1rem;
                    }
                }
                .contact-info{
                    width: 100%;
                    height: 25%;
                    span {
                        display: none;
                        
                    }
                    .col-12{
                        height: 25%;
                        img{
                            height: 100%;
                        }
                    }
                    .num-span{
                        display: block;
                        color: #0274bf;
                        font-size: .6rem;
                    }
                   .icons{
                    gap: 0rem;
                    margin-top:2rem;
                    .col-4{
                    
                        img{
                            height: 80%;
                            width: 80%;
                        }
                       
                       }
                   }
             
                }
            }
  
            }
        }
@media only screen and (min-width:577px) and (max-width:840px) {
    .contact-container{
      flex-direction: column;
      gap: 2rem;
      height: 70%;
      margin-top: 3rem;
      .contact-header {
        height: 15%;
      }
      .contact-body{
        justify-content: center;
        .form-container{
            padding: 2rem 1rem;
            .custom-form{
                height: 80%;
            }
        }
      }
      .contact-footer{
        height: 10%;
      }
    }
}






