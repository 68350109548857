
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
   
    .ham-btn {
      display: none;
        appearance: none;
        border: none;
        outline: none;
        background: none;
        position: absolute;
        top: 2.4rem;
        z-index: 8;
        right: 1rem;
        
        cursor: pointer;
        span {
            display: block;
            height: 3px;
            width: 25px;
            background-color: #fff;
            border-radius: .2rem;
            margin-bottom: 5px;
            transform-origin: 0 0 ;
            transition: .5s ;
        }
        &:hover :nth-child(2){
            
            transform: translateX(10px);
            background-color: #31A8FF;
        }
    }
    .ham-btn.isActive span:nth-child(1){
        transform: translate(2px, -4px) rotate(45deg);
        background-color: #31A8FF;
    }
    .ham-btn.isActive span:nth-child(2){
        opacity: 0;
        transform:translate(15px)
    }
    .ham-btn.isActive span:nth-child(3){
        transform: translate(-1px, -3px) rotate(-45deg);
        background-color: #31A8FF;
    }
}
.nav-container{
    position: sticky;
    top: 0;
    background-color: #000428;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 2;
    border-bottom: 3px solid #0071bc;
    box-shadow: 5px 10px 15px #31a9ff4d;
    .logo {
        padding: 0.4rem 1rem;
    }
    .navlist{ width: 100%;
        
        height: 100%;
        list-style-type: none;
        display: flex;
        margin: 0;
        padding: 0;
        align-items: center;
        transition: .5s all ease-in-out;
        .nav-item{
            margin: .2rem;
            .active{
                border: 2px solid #31A8FF;
            }
            &:hover {
                .nav-link {
                    border: 2px solid #31A8FF;
                }
            }
          
        }
        .nav-link{
            font-family: "Raleway" , sans-serif;
            font-weight: 500;
            color: #fff;
            text-decoration: none;
            margin: 0 .2rem;
            padding: .5rem 1rem;
            border-radius: .9rem;
            border: 2px solid transparent;
            transition: all ease-in-out .4s;
        }
    }
}

.non-active{
    border: 2px solid #ff0000;
}
@media only screen and (max-width:760px) {
    .navbar {
        .ham-btn{
            display: block;
        }
        
    }
    .nav-container{
        .navlist {
            display: none;
        }
    }
    .nav-container.navToggled {
        flex-direction: column;
        .navlist{
            display: flex;
            width: 100%;
           flex-direction: column;
           .nav-item{
            margin-top: .9rem;
           }
        }
       
    }


}
